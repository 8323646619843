<template>
  <div class="aops-free-detail-market-info">
    <div class="aop-info-title">市场属性</div>
    <div class="aop-info-warp">
        <div
            v-for="item in info"
            :key="item.id"
            class="aop-info-content">
            <div
                v-for="data in item.children"
                :key="data.prop"
                class="aop-info-item">
            <span class="aop-item-lable">{{data.label}}</span>
            <span class="aop-item-key-value">
                {{detail[data.key] || ''}}
            </span>
            </div>
        </div>
    </div>
  </div>
</template>
<script>

export default {
    props: {
        detail: {
            type: Object,
            default: () => ({
                activeColor: []
            })
        },
        info: {
            type: Object,
            default: () => []
        }
    }

};
</script>

<style lang="less">
@import "./market-info.less";
</style>
