<template>
  <div class="aops-free-detail-base-info">
    <div class="aop-info-title">基础属性</div>
    <div class="aop-info-warp">
        <div
            v-for="item in assetInfo"
            :key="item.id"
            class="aop-info-content">
            <div
                v-for="data in item.children"
                :key="data.prop"
                class="aop-info-item">
            <span class="aop-item-lable">{{data.label}}</span>
            <span v-if="data.key === 'color'" class="aop-item-colors">
               <div v-if="(detail.color || []).length >0">
                    <div v-for="color in detail[data.key]"
                        :key="color"
                        class="aop-item-color"
                        :style="`background:${color};`"></div>
                </div>
                <div v-if="(detail.color || []).length === 0" class="aop-item-key-value">未选择颜色属性</div>
            </span>
            <span v-else class="aop-item-key-value">
                <span v-if="item.type === 'tooltip' && detail[item.prop] && detail[item.prop].length > 10">
                    <el-tooltip class="item" effect="dark" content={detail[item.prop]} placement="top">
                        {{detail[data.key]}}
                    </el-tooltip>
                </span>
                <span v-else>{{detail[data.key] || ''}}</span>
                </span>
            </div>
        </div>
    </div>
  </div>
</template>
<script>

export default {
    props: {
        detail: {
            type: Object,
            default: () => ({
                activeColor: []
            })
        },
        assetInfo: {
            type: Object,
            default: () => []
        }
    }

};
</script>

<style lang="less">
@import "./base-info.less";
</style>
