<template>
  <div class="aops-free-detail-download">
      <div class="aop-info-title">下载属性</div>
      <div class="aop-download-checkbox">
        <span class="aop-item-lable">Resolution</span>
        <el-checkbox
          v-for="(item, index) in detail.texture_resolution"
          :key="index"
          v-model="checked"
          disabled
          >{{ item }}</el-checkbox
        >
      </div>
      <div class="aop-download">
        <!-- <el-item label="Mesh Format" class="name-input">
            <el-input v-model="detail.meshFormat"></el-input>
        </el-item> -->
         <span class="aop-item-lable">Mesh Format</span>
        <span class="aop-item-key-value">
            {{detail.mesh_format || ''}}
        </span>
        <div class="aop-download-levels">
            <div
                v-for="(item, index) in detail.model_levels"
                :key="index"
                class="aop-download-item">
                <el-checkbox
                    v-model="checked"
                    disabled>
                    {{ item }}
                </el-checkbox>
        </div>
        </div>
        <div class="aop-download-mesh"></div>
        <div class="aop-info-warp">
            <div
                v-for="item in info"
                :key="item.id"
                class="aop-info-content">
                <div
                    v-for="data in item.children"
                    :key="data.prop"
                    class="aop-info-item">
                <span class="aop-item-lable">{{data.label}}</span>
                <span class="aop-item-key-value">
                    {{detail[data.key] || ''}}
                </span>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => ({
                activeColor: []
            })
        },
        info: {
            type: Object,
            default: () => []
        }
    }, /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            checked: true
        };
    }
};
</script>
<style lang="less">
@import "./download.less";
</style>
