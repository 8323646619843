<template>
  <div class="aops-free-assets-detail">
    <div class="aop-back" @click="back">
        <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
    </div>
     <div class="aop-free-detail-wrap">
        <div class="aop-detail-title">
            <div>{{detail.name}}</div>
            <div
                v-for="(item, index) in detail.previews"
                :key="index"
                class="aop-detail-img">
            <img :src="item.url+ '?imageMogr2/thumbnail/x512/ignore-error/1/interlace/1'" alt=""/>
            </div>
        </div>
        <div class="aop-detail-content">
            <base-info :detail={...detail} :asset-info={...assetInfo} ></base-info>
            <market-info :detail={...detail} :info={...marketInfo}></market-info>
            <download :detail={...detail} :info={...downloadInfo}></download>
        </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '../../../../api/assets';
import BaseInfo from './base-info/base-info.vue';
import MarketInfo from './market-info/market-info.vue';
import Download from './download/download.vue';
import { assetInfo, marketInfo, downloadInfo } from '../data';

export default {
    components: {
        BaseInfo, MarketInfo, Download
    },
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            loading: false,
            detail: {},
            assetInfo,
            marketInfo,
            downloadInfo
        };
    },
    /**
     * 初始化
     */
    mounted() {
        this.init();
    },
    methods: {
    /**
    * 确认提交
    */
        init() {
            this.loading = true;
            const id = this.$route.query.id || 2930;
            // todo
            api.assetsInfo(id).then((resp) => {
                if (resp.data.code === 10200) {
                    this.detail = resp.data.data || {};
                    const categories = this.detail.categories.map((ele) => ele.label) || [];
                    this.detail = { ...this.detail,
                        cost: this.detail.cost || '0',
                        is_sss: !this.detail.is_sss ? '不需要' : '需要',
                        is_grouped: !this.detail.is_grouped ? '否' : '是',
                        is_full_screen: !this.detail.is_full_screen ? '不支持' : '支持',
                        model_state: this.detail.model_state === 1 ? '不封闭' : this.detail.model_state === 0 ? '封闭' : '',
                        categories: categories.join(','),
                        search_tags: (this.detail.search_tags || []).join(','),
                        tags: (this.detail.tags || []).join(','),
                        gloss: (this.detail.gloss || ['PNG']).join(','),
                        fuzz: (this.detail.fuzz || []).join(','),
                        material_preset: (this.detail.material_preset || ['Custom']).join(','),
                        metallic: (this.detail.metallic || ['PNG']).join(','),
                        cavity: (this.detail.cavity || ['PNG']).join(','),
                        normal: (this.detail.normal || ['PNG']).join(','),
                        opacity: (this.detail.opacity || []).join(','),
                        roughness: (this.detail.roughness || ['PNG']).join(','),
                        displacement: (this.detail.displacement || ['PNG']).join(','),
                        mesh_format: (this.detail.mesh_format || []).join(','),
                        ao: (this.detail.ao || ['PNG']).join(','),
                        scatter: (this.detail.scatter || []).join(','),
                        albedo: (this.detail.albedo || ['PNG']).join(','),
                        chinese_tags: (this.detail.chinese_tags || []).join(','),
                        specular: (this.detail.specular || []).join(','),
                        published_at: formatDate(this.detail.published_at * 1000, 'yyyy-MM-dd hh:mm:ss') || '--' };
                } else {
                    this.$$error(resp.data.message);
                }
                this.btnLoading = false;
            });
        },
        /**
        * 取消
        */
        back() {
            this.$router.push('/assets/free');
        }

    }
};
</script>

<style lang="less">
@import "./detail.less";
</style>
